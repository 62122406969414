import { Route, Switch } from 'react-router-dom';
import React, { useMemo } from 'react';
import SalesforceAuthenticator from '../components/SalesforceAuthenticator';
import PublicBottlePage from '../features/public/screens/PublicBottlePage';
import { FrontendEnvironment, getEnvironment } from '../util/amplifyConfig';
import LocalAuthenticator from '../components/LocalAuthenticator';
import ShopWorkInProgressPage from '../features/shop/screens/ShopWorkInProgressPage';
import PublicShopPage from '../features/shop/screens/PublicShopPage';
import PublicFreeSubscriptionPage from '../features/shop/screens/PublicFreeSubscriptionPage';

const shouldDisplayShop = process.env.REACT_APP_STAGE !== 'production';
const UnauthenticatedRoutes: React.FC<{}> = () => {
  const authenticator =
    getEnvironment() === FrontendEnvironment.LOCAL
      ? LocalAuthenticator
      : SalesforceAuthenticator;

  const shopRoutes = useMemo(() => {
    if (!shouldDisplayShop) {
      return [
        <Route
          key={'wip-/public/shop/'}
          exact
          path="/public/shop/*"
          component={ShopWorkInProgressPage}
        />,
      ];
    }

    return [
      <Route
        key={'/public/shop/'}
        exact
        path="/public/shop/"
        component={() => <PublicShopPage standalone={true} />}
      />,
      <Route
        key={'/public/shop/free'}
        exact
        path="/public/shop/free"
        component={() => <PublicFreeSubscriptionPage standalone={true} />}
      />,
      <Route
        key={'/public/shop/:plan'}
        exact
        path="/public/shop/:plan"
        component={() => <PublicShopPage standalone={true} />}
      />,
    ];
  }, []);

  // WARNING
  // Careful when adding conditional routes
  // These may not be wrapped in a <> </> tag
  // This will fuck up the router when the condition is not met
  // The following setup IS BROKEN and will yield a BLANK page
  // <Switch>
  // { showStuff &&
  //   <>
  //      <Route .../>
  //   </>
  // }
  //</Switch>
  return (
    <Switch>
      <Route
        key={'/public/inspect-bottle/:barcode'}
        exact
        path="/public/inspect-bottle/:barcode"
        component={PublicBottlePage}
      />

      {shopRoutes}

      <Route key={'catchall'} path="*" component={authenticator} />
    </Switch>
  );
};

export default UnauthenticatedRoutes;
