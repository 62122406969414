import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import VersionInfo from '../components/VersionInfo';
import AdministrationPage from '../features/administration/screens/AdministrationPage';
import BarcodeTestPage from '../features/administration/screens/BarcodeTestPage';
import GatewayAdministrationPage from '../features/administration/screens/GatewayAdministrationPage';
import GatewayEdit from '../features/administration/screens/GatewayEdit';
import MaintenancePage from '../features/administration/screens/MaintenancePage';
import ValveAdministrationPage from '../features/administration/screens/ValveAdministrationPage';
import ValveEdit from '../features/administration/screens/ValveEdit';
import * as commonActions from '../features/common/actions';
import { UserRole } from '../features/common/models/user-role';
import DigiDrumiBarcodePairingPage from '../features/digidrumi/screens/DigiDrumiBarcodePairingPage';
import DigiDrumiPage from '../features/digidrumi/screens/DigiDrumiPage';
import InventoryBarcodeScannerPage from '../features/inventory/screens/InventoryBarcodeScannerPage';
import InventoryBottlePage from '../features/inventory/screens/InventoryBottlePage';
import InventoryPage from '../features/inventory/screens/InventoryPage';
import GroupPage from '../features/groups/screens/GroupPage';
import DigiDrumiRedirectPage from '../features/digidrumi/screens/DigiDrumiRedirectPage';
import BarcodeTestPageQuaggaBase from '../features/administration/screens/BarcodeTestPageQuaggaBase';
import { ShopOrderConfirmationPage } from '../features/shop/screens/ShopOrderConfirmationPage';
import PublicShopPage from '../features/shop/screens/PublicShopPage';
import ShopWorkInProgressPage from '../features/shop/screens/ShopWorkInProgressPage';
import ShopPage from '../features/shop/screens/ShopPage';
import ShopFreeSubscriptionPage from '../features/shop/screens/ShopFreeSubscriptionPage';
import PublicFreeSubscriptionPage from '../features/shop/screens/PublicFreeSubscriptionPage';

const mapStateToProps = (state: RootState) => ({
  user: state.common.user,
});

const dispatchProps = {
  signOut: commonActions.signOutAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const shouldDisplayShop = process.env.REACT_APP_STAGE !== 'production';
const AuthenticatedRoutes: React.FC<Props> = ({ signOut, user }) => {
  const conditionalAdminRoutes = useMemo(() => {
    if (user?.role !== UserRole.Admin) {
      return [];
    }
    return [
      <Route
        key={'/admin/'}
        exact
        path="/admin/"
        component={AdministrationPage}
      />,
      <Route
        key={'/admin/valves'}
        exact
        path="/admin/valves"
        component={ValveAdministrationPage}
      />,
      <Route
        key={'/admin/edit-valve/:valveId'}
        exact
        path="/admin/edit-valve/:valveId"
        component={ValveEdit}
      />,
      <Route
        key={'/admin/gateways'}
        exact
        path="/admin/gateways"
        component={GatewayAdministrationPage}
      />,
      <Route
        key={'/admin/edit-gateway/:gatewayId'}
        exact
        path="/admin/edit-gateway/:gatewayId"
        component={GatewayEdit}
      />,
      <Route
        key={'/admin/barcode-scanner'}
        exact
        path="/admin/barcode-scanner"
        component={BarcodeTestPage}
      />,
      <Route
        key={'/admin/barcode-scanner-quagga-base'}
        exact
        path="/admin/barcode-scanner-quagga-base"
        component={BarcodeTestPageQuaggaBase}
      />,
      <Route
        key={'/admin/maintenance'}
        exact
        path="/admin/maintenance"
        component={MaintenancePage}
      />,
    ];
  }, [user]);

  const conditionalShopRoutes = useMemo(() => {
    if (!shouldDisplayShop) {
      return [
        <Route
          key={'wip-/shop/*'}
          exact
          path="/shop/*"
          component={ShopWorkInProgressPage}
        />,
        <Route
          key={'wip-/public/shop/*'}
          exact
          path="/public/shop/*"
          component={ShopWorkInProgressPage}
        />,
      ];
    }

    return [
      <Route key={'/shop/'} exact path="/shop/" component={ShopPage} />,

      <Route
        key={'/shop/free'}
        exact
        path="/shop/free"
        component={ShopFreeSubscriptionPage}
      />,
      <Route
        key={'/shop/exit'}
        exact
        path="/shop/exit"
        component={() => <ShopOrderConfirmationPage />}
      />,
      <Route
        key={'/shop/:plan'}
        exact
        path="/shop/:plan"
        component={ShopPage}
      />,
      <Route
        key={'/public/shop/'}
        exact
        path="/public/shop/"
        component={() => <PublicShopPage standalone={false} />}
      />,
      <Route
        key={'/shop/free'}
        exact
        path="/shop/free"
        component={PublicFreeSubscriptionPage}
      />,
      <Route
        key={'/public/shop/'}
        exact
        path="/public/shop/"
        component={() => <PublicShopPage standalone={false} />}
      />,
      <Route
        key={'/public/shop/:plan'}
        exact
        path="/public/shop/:plan"
        component={() => <PublicShopPage standalone={false} />}
      />,
    ];
  }, []);

  // WARNING
  // For a warning about conditional routes, see the
  // UnauthenticatedRoutes.tsx file
  return (
    <Switch>
      <Route
        key={'/'}
        exact
        path="/"
        render={() => <Redirect to="/inventory/" />}
      />
      <Route
        key={'/inventory/'}
        exact
        path="/inventory/"
        component={InventoryPage}
      />
      <Route
        key={'/inventory/inspect-bottle/:customer'}
        exact
        path="/inventory/inspect-bottle/:customer"
        component={InventoryBarcodeScannerPage}
      />
      <Route
        key={'/inventory/:customer/:material?'}
        exact
        path="/inventory/:customer/:material?"
        component={InventoryPage}
      />
      <Route
        key={'/inventory/inspect-bottle/:customer/:barcode'}
        exact
        path="/inventory/inspect-bottle/:customer/:barcode"
        component={InventoryBottlePage}
      />
      <Route
        key={'/public/inspect-bottle/:barcode'}
        exact
        path="/public/inspect-bottle/:barcode"
        component={InventoryBottlePage}
      />
      <Route
        key={'/digidrumi/'}
        exact
        path="/digidrumi/"
        component={DigiDrumiPage}
      />
      <Route
        key={'/digidrumi/pair-container/:customer/:serialNumber'}
        exact
        path="/digidrumi/pair-container/:customer/:serialNumber"
        component={DigiDrumiBarcodePairingPage}
      />
      <Route
        key={'/digidrumi/inspect-meter/:serialNumber?'}
        path="/digidrumi/inspect-meter/:serialNumber?"
        component={DigiDrumiRedirectPage}
      />
      <Route
        key={'/digidrumi/:customer/:serialNumber?'}
        path="/digidrumi/:customer/:serialNumber?"
        component={DigiDrumiPage}
      />

      <Route
        key={'/signout/'}
        exact
        path="/signout/"
        render={() => {
          signOut();
          return null;
        }}
      />
      <Route key={'/groups/'} exact path="/groups/" component={GroupPage} />
      <Route
        key={'/groups/:customer/:group?'}
        path="/groups/:customer/:group?"
        component={GroupPage}
      />

      <Route key={'/version/'} exact path="/version/" component={VersionInfo} />

      {conditionalShopRoutes}
      {conditionalAdminRoutes}
    </Switch>
  );
};

export default connect(mapStateToProps, dispatchProps)(AuthenticatedRoutes);
