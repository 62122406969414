import { Select } from '../../../stories/Select';
import React from 'react';
import { ApiCustomer } from '../../../models/openapi/openapiTypes';

interface Props {
  customers: ApiCustomer[];
  selectedCustomer?: ApiCustomer;
  onSelect: (customer: ApiCustomer) => void;
}

const CustomerNumberSelect: React.FC<Props> = ({
  customers,
  selectedCustomer,
  onSelect,
}) => {
  return (
    <Select
      label="Kundenkonto"
      id="customer"
      value={selectedCustomer?.customerId ?? 'NONE'}
      size="l"
      dropoutStyleOverrides={{ zIndex: 9999 }}
      onChange={value =>
        value && onSelect(customers.find(c => c.customerId === value)!)
      }
      options={customers.map(customer => ({
        label: `${customer.customerId} - ${customer.name}`,
        value: customer.customerId,
        additionalContent: `${customer.zipCode} ${customer.city}, ${customer.street}`,
      }))}
      undefinedOptionValue={'NONE'}
      search={'all'}
      showTooltips
    />
  );
};

export default CustomerNumberSelect;
